<template>
  <div class="px-3">
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
      ></v-progress-circular>
    </v-overlay>
    <v-row class="white rounded-xl px-5 font-weight-bold mb-2 text-left">
      <v-col cols="5">
        <div>Date</div>
      </v-col>
      <v-col cols="2">
        <div>Payment</div>
      </v-col>
      <v-col cols="2">
        <div>Status</div>
      </v-col>
      <v-col cols="3">
        <div>Invoice</div>
      </v-col>
    </v-row>
    <div class="mt-6" v-for="(history, index) in histories" :key="index">
      <v-row
        class="white rounded-xl px-5 font-weight-bold mb-2 text-left capitalize text-12"
      >
        <v-col cols="5" align-self="center">
          <a class="text-decoration-none">
            {{ new Date(history["created_at"]).toDateString() }}
          </a>
        </v-col>
        <v-col cols="2" align-self="center">
          <div v-if="history.billing_period === 'year'">
            {{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(history.upgrade.amount * 12 * 0.925)
            }}
          </div>
          <div v-else>
            {{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(history.upgrade.amount)
            }}
          </div>
        </v-col>
        <v-col cols="2" align-self="center">
          <v-chip
            :class="history.status === 'active' ? 'success' : 'error'"
            small
          >
            {{ history.status }}
          </v-chip>
        </v-col>
        <v-col cols="3" align-self="center">
          <div>#{{ history["invoice_number"] }}</div>
        </v-col>
      </v-row>
    </div>
    <v-pagination
      v-model="pagination.page"
      :length="pagination.total"
      :total-visible="7"
      @input="nextPage()"
      v-if="histories.length && pagination.total > 1"
    ></v-pagination>
    <div class="pt-4" v-if="!histories.length">No record found!</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "PaymentHistory",
  data() {
    return {
      pagination: {
        page: 1,
        total: 0,
      },
      histories: [],
      loading: false,
      toast: new ResponseHelper(),
    };
  },
  created() {
    this.paymentHistory();
  },
  methods: {
    ...mapActions("payments", ["payment_history"]),
    async paymentHistory(page) {
      if (!page) {
        page = "?page=" + this.pagination.page;
      }
      this.loading = true;
      try {
        const response = await this.payment_history(page);
        this.pagination.page = response.data["current_page"];
        this.pagination.total = response.data["last_page"];
        this.histories = response.data.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    nextPage() {
      let page = "?page=" + this.pagination.page;
      this.paymentHistory(page);
    },
  },
};
</script>

<style scoped></style>
